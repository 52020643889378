.progressVisualFull {
    display: flex;
    /* change the value if you want different thickness */
    height: 6px;
    margin: 20px 0;
  }
  
  .progressVisualPart {
    /* Number of the seconds for complete animation */
    transition: width 10s;
  }
  